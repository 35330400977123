import React from "react";
import { graphql } from 'gatsby';
import Layout from "../../components/layout";
import SEO from "../../components/seo";


import Gallery from '@browniebroke/gatsby-image-gallery'

const Fonster = ({ data }) => {
    const { path } = "gallery";
    const images = data.images.edges.map(({ node }) => node.childImageSharp)
    const mountains = data.mountains.edges.map(({ node }) => node.childImageSharp)
    const flowers = data.flowers.edges.map(({ node }) => node.childImageSharp)
    const flowersFields = data.flowersFields.edges.map(({ node }) => node.childImageSharp)
    const cities = data.cities.edges.map(({ node }) => node.childImageSharp)
    const seas = data.seas.edges.map(({ node }) => node.childImageSharp)
    const sunset = data.sunset.edges.map(({ node }) => node.childImageSharp)
    const forest = data.forest.edges.map(({ node }) => node.childImageSharp)
    const tropical = data.tropical.edges.map(({ node }) => node.childImageSharp)
    return (
        <Layout>
            <SEO title="Bildgalleri Fönster" />
            <div className="mainWrapper">
                <h1>Bildgalleri</h1>
                <p>Här visas de bilder som går att välja på till samtliga av våra fönster.</p>
                <h2>Favoriter</h2>
                <p>Dessa är våra personliga favorieter för en unik utsikt.</p>
                <Gallery images={images} />
                <h2>Berg</h2>
                <Gallery images={mountains} />
                <h2>Blommor</h2>
                <Gallery images={flowers} />
                <h2>Blommor och Ängar</h2>
                <Gallery images={flowersFields} />
                <h2>Broar och Städer</h2>
                <Gallery images={cities} />
                <h2>Sjöar</h2>
                <Gallery images={seas} />
                <h2>Solnedgångar</h2>
                <Gallery images={sunset} />
                <h2>Skog</h2>
                <Gallery images={forest} />
                <h2>Tropiskt</h2>
                <Gallery images={tropical} />
            </div>
        </Layout>
    )

}

export const pageQuery = (graphql`
query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 500
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
    }
}
    mountains: allFile(
      filter: { relativeDirectory: { eq: "gallery/fonster/berg" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 500
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
    }
}
    flowers: allFile(
      filter: { relativeDirectory: { eq: "gallery/fonster/blommor" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 500
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
    }
}
    flowersFields: allFile(
      filter: { relativeDirectory: { eq: "gallery/fonster/blommorAngar" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 500
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
    }
}
    cities: allFile(
      filter: { relativeDirectory: { eq: "gallery/fonster/broarStader" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 500
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
    }
}
    seas: allFile(
      filter: { relativeDirectory: { eq: "gallery/fonster/sjoar" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 500
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
    }
}
    sunset: allFile(
      filter: { relativeDirectory: { eq: "gallery/fonster/solnedgang" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 500
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
    }
}
    forest: allFile(
      filter: { relativeDirectory: { eq: "gallery/fonster/tradOskog" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 500
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
    }
}
    tropical: allFile(
      filter: { relativeDirectory: { eq: "gallery/fonster/tropiskt" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 500
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
    }
}
}
`)

export default Fonster;